@import '../../styles/propertySets.css';

.root {
}

.fieldWrapper {
  position: relative;
}

.field {
  margin: 0;
}

.label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  /* Position */
  margin: 0px;
  padding-bottom: 0;
}

.input {
  @apply --marketplaceH4FontStyles;
  padding: 6px 0 9px 0;
  margin: 0;

  &:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -57%);
}

.successStrokeColor {
  stroke: var(--successColor);
}

.errorStrokeColor {
  stroke: var(--failColor);
}

.error {
  /* Font */
  font-size: 15px;
  color: var(--failColor);

  margin: 4px 0 0;
}
