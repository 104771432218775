@import '../../styles/propertySets.css';

:root {
  --imageContainerWidth: 220px;
  --twoColumnsImageContainerWidth: 320px;
}

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--matterColorLight);
  background-color: var(--matterColorDark);

  /* Animation */
  transition: box-shadow var(--transitionStyleButton);
}

.rootWithScroll {
  box-shadow: var(--boxShadowMedium);
}

.rootWithShadow {
  box-shadow: var(--boxShadowMedium);
}

.rootWithBorder {
  border-bottom: 1px solid var(--matterColorNegative);
}

.menuContent {
  /* Size */
  @apply --marketplacePageWidth;

  /* Layout */
  position: relative;
  max-width: 750px;

  @media (--viewportLarge) {
    padding: 0px;
  }
}

.menuList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 0;

  & li {
    white-space: nowrap;
  }
}

.menuItem {
  /* Font */
  @apply --marketplaceH4FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.menuItemActive {
  background-color: var(--matterColorLight);

  & .menuItemLabel {
    color: var(--matterColor);

    &:after {
      content: '';

      /* Position */
      position: absolute;
      bottom: 0;

      /* Size */
      width: 0;
      height: 0;

      /* Triangle up */
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.menuItemLabel {
  /* Font */
  color: var(--matterColorLight);
  text-align: center;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  margin-top: 12px;
  margin-bottom: 12px;

  /* Animation */
  transition: all var(--transitionStyleButton);
}

.menuLink {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  text-transform: uppercase;
  text-align: center;
  margin: 0px;
}

.menuItemLink {
  @apply --marketplaceH4FontStyles;
  margin-top: 0;
  margin-bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--matterColorLight);
  transition: all var(--transitionStyleButton);

  /* Spacing */
  padding: 12px 48px;

  &:hover {
    color: var(--matterColor);
    background-color: var(--matterColorLight);
  }
}

.dropdown {
  /* Grid */
  display: grid;
  grid-template-columns: 1fr var(--imageContainerWidth);
  grid-gap: 48px;

  /* Position */
  position: absolute;
  left: 0;

  /* Layout */
  padding: 24px 34px;
  width: 100%;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  color: var(--matterColorDark);
}

.twoColumnsDropdown {
  grid-template-columns: 1fr var(--twoColumnsImageContainerWidth);
}

.dropdownContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
}

.twoColumnsDropdownContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.dropdownItem {
}

.dropdownItemLabel {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorDark);
  text-transform: capitalize;
  margin: 0 0 12px;
}

.dropdownList {
  margin: 0;
}

.dropdownListItem {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  text-transform: capitalize;
  margin: 0 0 3px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.dropdownListLink {
  color: var(--matterColor);
  transition: all var(--transitionButtonStyle);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.dropdownImageContainer {
}

.twoColumnsDropdownImageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  & .dropdownImage {
    height: 200px;
  }
}

.dropdownImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
