.previousSelections {
  margin: 44px 0 0;
}

.selections {
  display: flex;
  align-items: center;
  margin: 0;
}

.selection {
  text-transform: capitalize;
  opacity: 0.6;

  &:before {
    content: '>';
    margin: 0 8px;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
}
