@import '../../styles/propertySets.css';

.root {
}

.chevronRightContainer,
.chevronLeftContainer {
  display: none !important;

  @media (--viewportLarge) {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.chevronContainer {
  height: 173px !important;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.chevronLeft {
  right: auto;
  left: 0;
  border-radius: 2px 6px 6px 2px;

  & svg {
    transform: rotate(180deg);
    margin-left: 0;
    margin-right: 2px;
  }
}

.chevronIcon {
  height: 24px;
  width: 24px;
  color: var(--matterColor);
}
