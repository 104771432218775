@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  /* Size */
  /* height: 22px;
  width: 22px; */

  padding-top: 3px;

  &:hover {
    & .addToWishListIcon {
      fill: var(--priceColor);
    }
  }
}

.addToWishListIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & .addToWishListIcon {
      fill: var(--marketplaceColor);
    }
  }
}

.addToWishListSpinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addToWishListIcon {
  fill: var(--matterColor);
  transition: all 0.15s ease-in-out;
}

.activeWishListIcon {
  fill: var(--marketplaceColor);
}

.addToWishListSpinnerIcon {
  height: 22px;
  width: 22px;
}
