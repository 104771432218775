@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;

  /* Size */
  padding: 12px 24px;

  /* Style */
  background-color: var(--matterColorDark);

  @media (--viewportMedium) {
    justify-content: center;
    height: 44px;
    padding: 0px;
  }
}

.noticeText {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);

  /* Remove margin and set width */
  margin: 0;
  max-width: 80%;

  @media (--viewportMedium) {
    max-width: 100%;
  }
}

.closeIconContainer {
  /* Layout */
  display: flex;
  align-items: center;
  cursor: pointer;

  /* Position */
  position: absolute;
  right: 24px;

  &:hover {
    & .closeIcon {
      fill: var(--matterColorLightTransparent);
    }
  }
}

.closeIcon {
  /* Ensure that we remove stroke */
  fill: var(--matterColorLight);
  stroke: none;

  /* Animation */
  transition: fill var(--transitionStyleButton);
}
