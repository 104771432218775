@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;

  /* Style */
  background-image: linear-gradient(to right top, #ecece8, #ecece8);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    padding: 48px;
    border-radius: 16px;
  }

  @media (--viewportLarge) {
    padding: 96px;
  }
}

.titleWrapper {
  padding: 0 15px;

  @media (--viewportMedium) {
    max-width: 400px;
    padding-left: 0px;
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    padding-right: 0px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);
  text-transform: uppercase;

  /* Remove top and bottom margin */
  margin-top: 0;
  margin-bottom: 12px;
}

.subTitle,
.subTitleLineTwo {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  /* color: var(--matterColorLight); */
  color: black;
  font-weight: var(--fontWeightRegular);

  /* Remove margin */
  margin: 0;
}

.stravaFrame {
  margin-top: 48px;
  box-shadow: var(--boxShadowButton);

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}

.learnMoreButton {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);

  /* Style */
  background: var(--stravaColor);
  border: 1px solid transparent;
  border-radius: 4px;

  padding: 11px 15px;
  margin-top: 16px;

  /* Size */
  height: 44px;
  min-height: 44px;
  width: auto;

  /* Animation */
  transition: all var(--transitionButtonStyle);
}
