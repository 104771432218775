:root {
  --gray-light: #ececed;
  --gray-accent: #e9e9ea;
}

@keyframes loading {
  0% {
    background-position-x: 0;
  }
  40%,
  100% {
    background-position-x: -200%;
  }
}

.block {
  display: block;
  height: 240px;
  width: 100%;
  border-radius: 0.125rem;
  background: var(--gray-accent);
}

/* Shared placeholder styles. */
.loadingBlock::before,
.loadingBlock::after {
  content: '';
  display: block;
  background-image: linear-gradient(
    110deg,
    var(--gray-light) 73%,
    var(--gray-accent) 75%,
    var(--gray-light) 77%,
    var(--gray-light) 78%,
    var(--gray-accent) 84%,
    var(--gray-accent) 88%,
    var(--gray-light) 94%,
    var(--gray-light) 100%
  );
  background-size: 200% 100%;
  background-position: 0 center;
  border-radius: inherit;

  animation: loading 2s ease-in-out infinite;
}

/* Image placeholder. */
.loadingBlock::before {
  width: 100%;
  height: 200px;
}

/* Text placeholder. */
.loadingBlock::after {
  animation-delay: 50ms;
  width: 92%;
  height: 1rem;
  margin-top: 0.75rem;
}
