@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.retailPriceInput {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--matterColorLight);
  height: 98px;
  width: 100%;
  padding: 24px;
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    position: unset;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 24px;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.payout {
  /* Font */
  @apply --marketplaceSmallFontStyles;

  /* Style */
  background-color: var(--matterColorBright);
  border: 1px solid var(--matterColorNegative);
  border-left: 5px solid var(--marketplaceColor);
  border-radius: 5px;

  /* Position and size */
  padding: 12px;
  margin: 24px 0 0;
}
