@import '../../styles/propertySets.css';

.root {
  margin-right: 24px;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0px;
    }
  }

  @media (--viewportMedium) {
    margin-right: 0px;
  }
}

.cardWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33vw;

  @media (--viewportMedium) {
    width: auto;
  }
}

.userName {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  letter-spacing: 0.14px;

  /* Spacing */
  margin-top: 24px;
  margin-bottom: 0;

  /*   
    Add dot at the end of each username
  */
  &:after {
    content: '.';
  }
}
