.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 32px;
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.category {
  composes: formMargins;
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--matterColorLight);
  height: 98px;
  width: 100%;
  padding: 24px;
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    position: unset;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 72px;
  }
}
