.root {
  display: flex;
  flex-direction: column;
}

.rootWithMap {
  & .listingCards {
    @media (--viewportMedium) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  & .pagination {
    min-width: 100%;
  }
}

.listingCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  padding: 0 24px 24px 24px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;

    /* Remove padding */
    padding-left: 0px;
    padding-right: 0px;
  }
}

.listingCard {
  position: relative;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px 24px;

  @media (--viewportMedium) {
    padding: 0 0 48px;
    align-self: flex-end;
    min-width: 400px;
  }
}
