@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Align text */
  text-align: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  text-transform: uppercase;
  margin-top: 0;
  max-width: 440px;
}

.shopNow {
  @apply --marketplaceButtonStylesSecondary;

  /* Custom styles */
  max-width: 286px;
  margin: 0;
  padding: 0;
  border-radius: 50px;
}

.shopNowLink {
  color: var(--matterColor);

  /* We need to ensure that link 
     takes 100% of the parent button */
  width: 100%;
  height: 50px;
  line-height: 50px;
}
