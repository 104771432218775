@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 24px;
  transition: var(--transitionStyleButton);

  &:first-child {
    margin-left: 24px;
  }
}

.rootListingSold {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
    cursor: pointer;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  width: 70vw;

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-top: 6px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);
  letter-spacing: 0.14px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  align-items: start;
}

.title {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  letter-spacing: 0.14px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  /* Limit one line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.badgeContainer {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 2;

  display: flex;
  align-items: center;

  @media (--viewportMedium) {
    top: 12px;
    right: 12px;
  }
}

.badge {
  /* Style */
  background-color: var(--marketplaceColor);
  /* border-radius: 4px; */
  padding: 7px 12px 6px;
}

.badgeText {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);
  text-transform: uppercase;

  /* Margin */
  margin: 0;
}

.soldBadge {
  background-color: var(--priceColor);
  color: var(--matterColorDark);
}
