@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  /* Layout */
  display: flex;
  align-items: center;

  /* Position */
  position: relative;

  /* Size */
  width: 100%;

  @media (--viewportMedium) {
    width: 350px;
  }
}

.field {
  margin: 0;
  width: 100%;
}

.input {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-align: left;

  /* Style */
  background: var(--matterColorLight);
  appearance: none;
  border: 1px solid var(--matterColorNegative);
  border-radius: 0;

  /* Size */
  width: 100%;
  height: 40px;
  padding-bottom: 3px;
  padding-left: 10px;

  /* Spacing */
  margin: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorNegative);
  }
}

.success,
.error {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  text-align: center;

  /* Spacing */
  margin-top: 12px;
  margin-bottom: 0px;
}

.success {
  color: var(--successColor);
}

.error {
  color: var(--failColor);
}

.submitButtonWrapper {
  /* Layout */
  display: flex;
  align-items: center;

  /* Style */
  border: 1px solid var(--matterColorNegative);
  height: 38px;

  /* Position */
  position: absolute;
  right: 0;
}

.submitButton {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-transform: uppercase;
  text-align: center;

  /* Style */
  background: var(--matterColorLight);
  content: '';
  margin: 0;
  border: 0;

  /* Size */
  height: 100%;
  min-height: 38px;
  width: 40px;
  padding: 0;

  &:hover,
  &:focus {
    color: var(--matterColor);
    background: var(--matterColorLight);
  }

  /* Reduce spinner icon size */
  & svg {
    height: 16px;
    width: 16px;
  }
}
