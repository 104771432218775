@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentAddressField {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 0 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.shippingMethodField {
  padding-top: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.shippingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.shippingMethodsContainer {
}

.shippingMethods {
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin: 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.shippingMethod {
  position: relative;

  /* Layout */
  display: flex;
  align-items: flex-start;

  /* Size */
  width: 100%;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowMedium);
  border-radius: 2px;
  padding: 24px;

  /* Animation */
  cursor: pointer;
}

.shippingTitle {
  color: var(--matterColor);

  /* Layout */
  display: flex;
  align-items: center;

  margin-top: 0;
  margin-bottom: 0;
}

.shippingText {
  @apply --marketplaceSmallFontStyles;

  margin-top: 6px;
  margin-bottom: 0;
}

.shippingIcon {
  width: 22px;
  height: auto;

  margin-top: -3px;
  margin-right: 6px;
}

.shippingRadioButton {
  padding-top: 4px;

  & label {
    padding-top: 0px;

    & span {
      height: auto;
      padding: 0;
    }
  }
}

.shippingOverlay {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  /* Style */
  background-color: rgba(0, 0, 0, 0.2);
}

.disabledShippingMethod {
  opacity: 0.5;
  pointer-events: none;
}

.hiddenShippingMethod {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.hiddenShippingRadioButton .shippingRadioButton {
  display: none;
}

.shippingError {
  /* Font */
  @apply --marketplaceSmallFontStyles;

  /* Layout */
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px;

  /* Style */
  padding: 12px;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 12px;
}

.shippingErrorIcon {
  height: 18px;
  width: 18px;

  margin-top: 3px;
}

.shippingAddressContainer {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 12px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.shippingAddressBox {
  display: flex;
  align-items: center;
  gap: 16px;

  /* Style */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowMedium);
  border-radius: 2px;

  padding: 24px;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.shippingAddressBoxDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.shippingAddress {
  @apply --marketplaceSmallFontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.locationIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  height: 28px;
  width: 28px;

  background-color: var(--matterColorNegative);
  border-radius: 50%;
}

.editShippingAddress {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  @apply --marketplaceSmallFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 12px;

  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);

    & .editIcon,
    & .editIcon g,
    & .editIcon path {
      stroke: var(--marketplaceColor);
    }
  }
}

.editIcon,
.editIcon g,
.editIcon path {
  stroke: var(--matterColor);
}
