@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-top: 24px;
  margin-bottom: 0px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 24px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);
  margin-top: 0px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 4px;
    margin-bottom: 18px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: 24px auto auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
  }
}

.smallPrint {
  display: none;

  @media (--viewportLarge) {
    display: block;
    @apply --marketplaceTinyFontStyles;
    color: var(--matterColorAnti);
    text-align: center;
    margin: auto 24px 12px 24px;
    flex-shrink: 0;
    margin-top: auto;
  }
}

.submitButtonContainer {
  margin-top: 34px;
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.bookButton,
.contactButton {
}

.contactButton {
  margin-top: 12px;
}
