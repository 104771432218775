@import '../../styles/propertySets.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 240px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  /* Position component */
  width: initial;
  margin: 4px 0 0;
  padding: 0;
}

.navigationLink {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 6px;
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.authenticationGreeting {
  /* Font */
  @apply --marketplaceH1FontStyles;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  & span {
    /* black left border */
    border-left: 5px solid black;
    padding-left: 12px;
  }
}

.accordonMenu {
  margin-top: 24px;
  width: 100%;
}

.accordionItem {
  position: relative;

  &:last-child {
    border-bottom: 0;

    & .accordionItemNav {
      border-bottom: 0;
    }
  }
}

.accordionItem {
  width: 100%;
}

.accordionItemNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);
  color: var(--matterColor);
  font-weight: 600;
}

.accordionSubItem {
  position: relative;
  margin-left: 12px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.accordionSubItemLink {
  display: block;
  position: relative;
  padding: 12px 0;
  margin-left: 12px;
  border-bottom: 1px solid var(--matterColorNegative);
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  &:last-child {
    border-bottom: 0;
  }
}

.accordionSubItemNav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid var(--matterColorNegative);

  &:last-child {
    border-bottom: 0;
  }
}

.itemLabel,
.subItemLabel {
  font-weight: var(--fontWeightSemiBold);
  transition: all 0.25s ease-in-out;
}

.inboxLink,
.cartLink {
  justify-content: flex-start;
}

.plusIcon {
  position: relative;
  height: 10px;
  width: 10px;

  &:after,
  &:before {
    position: absolute;
    content: '';
    background-color: var(--matterColor);
    transition: transform 0.25s cubic-bezier(0.75, 0.02, 0.5, 1),
      color 0.25s cubic-bezier(0.75, 0.02, 0.5, 1);
  }

  &:before {
    height: 11.5px;
    width: 2px;
    top: 0;
    left: 50%;
  }

  &:after {
    width: 11.5px;
    height: 2px;
    left: 0;
    top: 50%;
  }
}

.activeAccordionItem {
  & .itemLabel {
    color: var(--marketplaceColor);
  }

  & .arrow {
    stroke: var(--marketplaceColor);
    fill: var(--marketplaceColor);

    /* Animation */
    transform: rotate(90deg);
  }
}

.arrow {
  /* Animation */
  transition: all 0.25s ease-in-out;
}

.activeAccordionSubItem {
  & .subItemLabel {
    color: var(--marketplaceColor);
  }

  & .plusIcon {
    &:before {
      transform: rotate(90deg);
      background-color: var(--marketplaceColor);
    }

    &:after {
      transform: rotate(1turn);
      background-color: var(--marketplaceColor);
    }
  }
}
