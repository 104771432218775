@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  gap: 18px;
  padding: 24px;
  width: 100%;

  /* Style */
  background: var(--matterColorLight);
  box-shadow: var(--boxShadowMedium);
  border-radius: 2px;

  @media (--viewportMedium) {
    width: fit-content;
  }
}

.shippingAddressList {
  margin: 0;

  & li {
    line-height: 24px;
  }

  & li {
    &:nth-child(1) {
      font-weight: var(--fontWeightBold);
    }
    &:nth-child(2) {
      margin-bottom: 12px;
    }
  }
}

.locationIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: var(--matterColorNegative);
  border-radius: 50%;
}
