@import '../../styles/propertySets.css';

:root {
  --ActiveLine: {
    content: '';

    /* Line position */
    position: absolute;
    bottom: 0;
    left: 0;

    /* Line layout */
    height: 3px;
    width: 100%;
    background-color: var(--marketplaceColor);
  }
}

.header {
  /* Background */
  background: linear-gradient(90deg, var(--marketplaceColor) 21px, transparent 1%) center,
    linear-gradient(var(--marketplaceColor) 21px, transparent 1%) center, lightgray;
  background-size: 22px 22px;

  /* Formatting */
  text-align: center;
  border-bottom: 1px solid var(--matterColorNegative);
  color: var(--matterColorLight);

  /* Layout */
  padding-top: 12vw;
  padding-bottom: 12vw;
  padding-left: 48px;
  padding-right: 48px;

  @media (--viewportMedium) {
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 0;
    padding-right: 0;
  }
}

.largeHeader {
  padding-top: 7vw;
  padding-bottom: 7vw;
}

.heroImageHeader {
  background-size: cover;
  background-repeat: no-repeat;

  padding-top: 20vw;
  padding-bottom: 20vw;
  @media (--viewportMedium) {
    padding-top: 13.5vw;
    padding-bottom: 13.5vw;
  }
}

.headerTitle {
  line-height: 40px;
  font-size: 26px;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 48px;
  }
}

.headerSubTitle {
  /* Make every paragraph add 
      same spacing at the top */
  margin: 34px auto 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    width: 330px;
  }
}

.headerLinks {
  /* Layout */
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;

  /* Formatting */
  text-align: center;
  border-bottom: 1px solid var(--matterColorNegative);

  /* Background */
  background-color: var(--marketplaceColorBright);

  /* Settings */
  overflow-x: scroll;

  & .active {
    color: var(--matterColorDark);

    &:after {
      @apply --ActiveLine;
    }
  }

  @media (--viewportMedium) {
    justify-content: center;
    overflow-x: hidden;
  }
}

.headerLink {
  min-height: 60px;
  white-space: nowrap;
  margin-right: 24px;

  &:first-child {
    margin-left: 24px;
  }

  @media (--viewportMedium) {
    margin: 0px;
  }
}

.pageLink {
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  text-transform: capitalize;

  /* Layout */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  padding: 0;
  margin: 0;

  @media (--viewportMedium) {
    padding: 0 24px;
  }

  &:hover {
    color: var(--matterColorDark);

    &:after {
      @apply --ActiveLine;
    }
  }
}
