@import '../../styles/propertySets.css';

.root {
}

.inputWithIcon {
  display: flex;
  align-items: center;
  padding-bottom: 6px;

  /* Wrap the border */
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
}

.desktopIcon {
  /* Layout */
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;

  /* Style */
  background-color: var(--matterColorLight);

  /* Rotate the icon */
  transform: rotate(280deg);
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceTinyFontStyles;
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}
