@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  align-items: center;
  flex-direction: column;

  /* fill */
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowMedium);
}

.navigationWrapper {
  /* Layout */
  @apply --marketplacePageWidth;
  padding-top: 17px;
  padding-bottom: 17px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (--viewportLarge) {
    padding: 21px 36px 21px 36px;
  }
}

.logoContainer {
  /* Layout */
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: -3px;
}

.logo {
  height: 32px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
}

.navigationLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}

/* Navigation link */
.navigationLink {
  margin: 0;
  color: var(--matterColor);
}

.inboxLink {
  margin-right: 24px;
}

.notificationLink {
  position: relative;
}

.notificationDot {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-size: 10px;
  line-height: 1;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Position */
  position: absolute;
  top: -4px;
  right: -5px;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  /* Style */
  background-color: var(--failColor);
  box-shadow: var(--boxShadowMedium);
  border-radius: 50%;

  /* Size */
  width: 16px;
  height: 16px;
}

.notificationCount {
}

.wishlistLink {
  margin-right: 24px;
}

.notAuthenticatedProfileLink {
  margin: 0 0 0 24px;
}

/* Profile menu */
.profileMenu {
  width: auto;
  height: 100%;
  position: relative;
  outline: none;
  margin-left: 24px;
}

.profileMenuLabel {
  padding: 0;
  border: 0;
  margin: 0;
}

.profileMenuIsOpen {
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
  margin-top: 12px;
}

.avatarContainer {
  /* Layout */
  display: flex;
  align-items: center;

  border-radius: 20px;
  padding: 4px 6px 4px 12px;
  box-shadow: var(--boxShadowMedium);
}

.avatar {
  height: 28px;
  width: 28px;

  & span {
    font-size: 13px;
  }
}

.avatarDisplayName {
  @apply --marketplaceTinyFontStyles;
  margin: 0 8px 0 0;
  white-space: nowrap;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.searchBar {
  display: flex;
  align-items: center;
  flex: 1;
}

.switchContainer {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.switchOption {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  margin: 0;

  /* Animation */
  transition: all var(--transitionStyleButton);
}

.switchOptionActive {
  color: var(--marketplaceColor);
}

.switch {
  margin: 0 6px;
}

.navigationIcon {
  transition: all 0.2s ease-in-out;
}
