@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  display: grid;
  grid-template-columns: 1fr;
  flex-shrink: 0;
  margin: 0;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}

.rentImagesField {
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.thumbnail {
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  overflow: hidden;

  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: 55%;

  @media (--viewportMedium) {
    padding-bottom: calc(100% * (2 / 3));
  }
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypesWithMargin {
  margin: 5px 0;
}

.imageTypeText {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.desktopTips {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileTips {
  @media (--viewportMedium) {
    display: none;
  }
}

.tips {
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 15px 0 0;
  }
}

.tipsWithMargin {
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 39px 0 0;
  }
}

.tipsList {
  margin: 0px;
  padding-left: 48px;
  list-style: initial;
}

.tipsLabel {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
  margin-top: 0;
  margin-bottom: 0;
}

.tip {
  @apply --marketplaceBodyFontStyles;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;
}

.error {
  color: var(--failColor);
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--matterColorLight);
  height: 98px;
  width: 100%;
  padding: 24px;
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    position: unset;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
}

.submitButton {
  flex-shrink: 0;
  margin-top: 0px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
