@import '../../styles/propertySets.css';

.root {
  /* Style */
  background: rgb(242, 242, 247);
  border-radius: 16px;

  /* Size */
  width: 100%;
  height: 100%;
  margin-right: 24px;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0px;
    }
  }

  @media (--viewportMedium) {
    margin-right: 0px;
  }
}

.reviewContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Size */
  padding: 24px;
  margin-right: 24px;
  width: 60vw;

  @media (--viewportMedium) {
    width: auto;
  }
}

.listingTitle {
  /* Font */
  font-weight: var(--fontWeightMedium);
  letter-spacing: 0.14px;

  /* Spacing */
  margin-top: 0;
  margin-bottom: 12px;

  /* Limit 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.upperContent {
}

.content {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);

  /* Remove margin */
  margin: 0;

  /* Limit 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @media (--viewportMedium) {
    -webkit-line-clamp: 3; /* number of lines to show */
  }
}

.stars {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.starContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  background: var(--marketplaceColor);
  border-radius: 6px;

  /* Size */
  height: 24px;
  width: 24px;
  margin-right: 2px;
}

.star {
  width: 12px;
  height: 12px;
  fill: var(--matterColorLight);
}

.author {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.avatar {
  height: 32px;
  width: 32px;
}

.authorDisplayName {
  font-weight: var(--fontWeightMedium);
  letter-spacing: 0.14px;
  margin: 0 0 0 10px;
}
