@import '../../styles/propertySets.css';

.slider {
}

.sliderItemContainer {
}

.sliderItem {
  padding: 48px;
  color: var(--matterColorLight);

  /* Style */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (--viewportMedium) {
    padding: 250px 125px 48px;
    border-radius: 4px;
  }
}

.itemGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  height: 307px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    height: unset;
  }
}

.leftContent {
  position: relative;
  max-width: 200px;

  @media (--viewportMedium) {
    max-width: 180px;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    display: unset;
  }
}

.itemTitle {
  margin: 0px;
  font-size: 32px;
  line-height: 38px;
}

.itemText {
  @apply --marketplaceMessageFontStyles;
  margin: 0px;
}

.sliderArrows {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 24px;

  @media (--viewportMedium) {
    justify-content: flex-start;
    max-width: 120px;
    margin-top: 32px;
  }
}

.sliderArrow {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  &:hover {
    & .sliderArrowIcon {
      & g,
      & path {
        stroke: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.sliderNextArrow {
  transform: rotate(180deg);
  margin-left: 24px;
}

.sliderArrowIcon {
  display: inline !important;
}

.itemOrder {
  /* Font */
  font-size: 14px;

  /* Layout */
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 15px;
    justify-content: center;
    margin-bottom: 0px;

    /* Position */
    position: absolute;
    left: -72px;
    top: -32px;
  }

  &:before {
    /* Layout */
    display: block;
    content: '';
    width: 24px;
    height: 2px;
    margin-right: 12px;

    /* Style */
    background: var(--matterColorLight);

    @media (--viewportMedium) {
      width: 36px;
    }
  }
}
