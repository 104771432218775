@import '../../styles/propertySets.css';

.root {
  padding: 72px 24px;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 72px 0;
  }
}

.titleWrapper {
  text-align: center;
  margin-bottom: 24px;
}

.title {
  @apply --marketplaceH2FontStyles;
  letter-spacing: 1.14px;

  /* Remove top and bottom margin */
  margin-top: 0;
  margin-bottom: 0;
}

.form {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  width: 100%;
}
