@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  padding: 48px 0;

  /* Style */
  background-color: var(--marketplaceColorBright);

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.rootWithBorder {
  @media (--viewportMedium) {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  /* Layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Apply page width */
  @apply --marketplacePageWidth;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--marketplaceColor);
  border-radius: 50%;

  width: 30px;
  height: 30px;

  margin-right: 12px;
  transition: all 0.15s ease-in-out;

  &:hover {
    background: var(--marketplaceColorLight);
  }

  & svg {
    height: 18px;
    width: 18px;
    fill: var(--matterColorLight);

    &:hover {
      fill: var(--matterColorLight);
    }
  }
}

.instagramIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--marketplaceColor);
  border-radius: 50%;

  width: 28px;
  height: 28px;

  margin-right: 12px;
  transition: all 0.15s ease-in-out;

  &:hover {
    background: var(--marketplaceColorLight);
  }

  & svg {
    height: 16px;
    width: 16px;
    fill: var(--matterColorLight);

    &:hover {
      fill: var(--matterColorLight);
    }
  }
}

.stravaIcon {
  margin-right: 0px;

  & svg {
    fill: var(--matterColorLight);
    transition: all var(--transitionStyleButton);
  }
}

/* Footer links */
.links {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.list {
  /* Layout */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  margin-bottom: 2px;

  &:first-child {
    margin-bottom: 12px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

.link {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.listTitle {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  text-transform: uppercase;
  line-height: 20px;
  color: var(--matterColorDark);
  letter-spacing: 1.14px;
}

.socialLinks {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.socialAndLanguageContainer {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Paddings */
  padding: 32px 0;
  margin: 0 0 32px;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.paymentLinks {
  display: flex;
  align-items: center;
}

.paymentIcon {
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.organizationInfo {
}

.organizationCopyright {
  /* Font */
  @apply --marketplaceSmallFontStyles;

  /* Size */
  margin: 24px 0 0;

  & a {
    color: var(--matterColor);

    &:hover {
      color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0px;
  }
}

.copyrightWrapper {
  /* Layout */
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
