@import '../../styles/propertySets.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;

  & input {
    height: 36px;

    @media (--viewportMedium) {
      height: 40px;
    }
  }
}

.building {
  margin: 0;
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--matterColorLight);
  height: 98px;
  width: 100%;
  padding: 24px;
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    position: unset;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 72px;
  }
}

.locationFields,
.shippingFields {
  margin-bottom: 48px;

  @media (--viewportMedium) {
    max-width: 70%;
  }
}

.shippingFields {
  margin: 0;
}

.locationFieldsHeading,
.shippingFieldsHeading {
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.shippingFieldsHeadingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  & h3 {
    margin: 0;
  }
}

.listingAddress {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
  }
}

.editShippingAddress {
  display: inline-flex;
  align-items: center;
  gap: 6px;

  @apply --marketplaceSmallFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 12px;

  cursor: pointer;

  &:hover {
    color: var(--marketplaceColor);

    & .editIcon,
    & .editIcon g,
    & .editIcon path {
      stroke: var(--marketplaceColor);
    }
  }
}

.editIcon,
.editIcon g,
.editIcon path {
  stroke: var(--matterColor);
}

.tooltipContainer {
  display: none !important;

  @media (--viewportMedium) {
    display: flex !important;
    position: relative;
    bottom: 1px;
  }
}

.tooltipIcon {
  height: 20px;
  width: 20px;
}

.mobileHelp {
  @apply --marketplaceSmallFontStyles;
  text-transform: none;

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 12px;

  @media (--viewportMedium) {
    display: none;
  }
}

.noShippingAddress {
  margin: 0;
}
