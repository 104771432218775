@import '../../styles/propertySets.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.predefinedValueContainer {
  /* Font */
  @apply --marketplaceInputStyles;

  display: flex;
  align-items: center;
}

.predefinedValue {
  @apply --marketplaceDefaultFontStyles;
  line-height: 24px;

  /* Remove margin and padding */
  padding: 0px;
  margin: 0px;
}

.predefinedValueInput {
  /* Remove margin and padding */
  padding: 0px;
  margin: 0px;

  /* Remove border */
  border-bottom: 0px;
}
