.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px calc(98px + 24px) 24px;

  @media (--viewportMedium) {
    padding: 11px 24px 0 24px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 0;

  @media (--viewportLarge) {
    padding: 1px 0 7px 0;
  }
}

.notificationBox {
  margin-top: 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 32px;

    max-width: 70%;
  }
}
