@import '../../styles/propertySets.css';

.root {
}

.titleWrapper {
  margin-bottom: 24px;
  padding-left: 24px;

  @media (--viewportMedium) {
    padding-left: 0px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  text-transform: uppercase;

  /* Remove top and bottom margin */
  margin-top: 0;
  margin-bottom: 0;
}

.subTitle {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);

  /* Remove margin */
  margin: 0;
}

.desktopSlider {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.mobileSlider {
  @media (--viewportMedium) {
    display: none;
  }
}
