@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
}

.heroContent {
  @media (--viewportMedium) {
    display: grid;
    grid-gap: 12px;
    min-height: 450px;
    grid-template-columns: 1fr 1fr;
  }
}

.rightItems {
  & .heroItem {
    padding-top: 80%;

    @media (--viewportMedium) {
      padding-top: unset;
    }
  }

  @media (--viewportMedium) {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr;
  }
}

.heroItem {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--borderRadius);
  background-position: center;
  padding-top: 100%;

  /* Style */
  background-image: url('./images/left.jpg');
  background-size: cover;

  @media (--viewportMedium) {
    padding-top: unset;
  }

  &:hover {
    & .heroShadow {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.heroSecondItem {
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('./images/top-right.jpg');
}

.heroThirdItem {
  background-image: url('./images/bottom-right.jpg');
}

.heroShadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);

  /* Animation */
  transition: all 0.3s ease-in-out;

  @media (--viewportMedium) {
    background: radial-gradient(100% 100% at 0 100%, #000 0, transparent 50%);
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroItemContent {
  /* Layout  */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  padding: 32px;

  /* Size */
  height: 100%;
  width: 100%;

  @media (--viewportMedium) {
    padding: 24px;
  }
}

.heroItemTitle {
  /* Font */
  font-size: 32px;
  line-height: 42px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorLight);
  text-transform: uppercase;
  letter-spacing: 0.14px;
  text-shadow: 0 0 7px var(--matterColorDark);

  /* Space */
  margin-top: 0;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;

    margin-top: 0;
    margin-bottom: 0;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroItemSubTitle {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-size: 16px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightRegular);
  text-shadow: 0 0 3px var(--matterColorDark);

  /* Space */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
