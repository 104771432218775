@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.picker {
  width: 180px;
}
