@import '../../styles/propertySets.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.fields {
  & > div,
  & > fieldset {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.error {
  color: var(--failColor);
}

.submitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: var(--matterColorLight);
  height: 98px;
  width: 100%;
  padding: 24px;
  box-shadow: var(--boxShadowTop);

  @media (--viewportMedium) {
    position: unset;
    background-color: transparent;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
  }
}

.submitButton {
  margin-top: 0;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.showMoreContainer {
  /* Font */
  @apply --marketplaceBodyFontStyles;
  color: var(--marketplaceColor);

  /* Animation */
  cursor: pointer;
  opacity: 0.8;
  transition: opacity var(--transitionButtonStyle);

  @media (--viewportMedium) {
    /* Layout */
    max-width: 60%;
  }

  &:hover {
    opacity: 1;
  }
}

.showMoreIcon {
  margin-left: 6px;

  /* Fill */
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}
