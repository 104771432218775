@import '../../styles/propertySets.css';

.root {
  /* Bg */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('./images/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 124px 0;

  @media (--viewportMedium) {
    margin-top: 24px;

    /* Style */
    border-radius: 8px;
  }
}

.title {
  /* Font */
  font-size: 28px;
  line-height: 36px;
  color: var(--matterColorLight);
  text-align: center;

  margin: 0;
  padding: 0 36px;

  @media (--viewportMedium) {
    font-size: 34px;
    line-height: 48px;
    padding: 0;
  }
}

.highlightedText {
  text-transform: uppercase;
  color: #ffffff;
}
