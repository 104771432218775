@import '../../styles/propertySets.css';

.root {
  border-radius: 4px;
}

.contentContainer {
  border-radius: 4px;
}

.content {
  padding: 48px;
  color: var(--matterColorLight);

  /* Style */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (--viewportMedium) {
    padding: 250px 125px 48px;
    border-radius: 4px;
  }
}

.contentGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  height: 270px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    height: unset;
  }
}

.leftContent {
  position: relative;

  @media (--viewportMedium) {
    max-width: 180px;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    display: unset;
  }
}

.itemTitle {
  margin: 0px;
  font-size: 32px;
  line-height: 38px;
  max-width: 200px;

  @media (--viewportMedium) {
    max-width: auto;
  }
}

.itemText {
  @apply --marketplaceMessageFontStyles;
  margin: 0px;
}
