@import '../../styles/propertySets.css';

/* Cart Checkout Container */
.root {
  @media (--viewportLarge) {
    flex-shrink: 0;

    /* Dimensions */
    width: 409px;
    padding-bottom: 34px;

    /* Position */
    margin: 120px 0 24px 0;

    /* Coloring */
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
  }
}

.listingsPanel {
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px;

  @media (--viewportMedium) {
    padding: 24px 48px 0px;
  }
}

.listingCard {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }
}

.listingCardInfo {
  padding-left: 24px;
}

.listingTitle {
  @apply --marketplaceSmallFontStyles;
  margin: 0px;
}

.priceContainer {
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0px;
}

.listingImageWrapper {
  width: 120px;
  height: 100px;
  box-shadow: var(--boxShadowLight);
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.listingImage {
  height: 100%;
  border-radius: var(--borderRadius);
}

.listingInfo {
  flex: 1 1;
  padding-left: 24px;

  /* Font */
  line-height: 1.5;
}

.highlightedText {
  color: var(--marketplaceColor);
}
