@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 16px 24px;

  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
  border-radius: 4px;
}

.notificationText {
  line-height: 28px;

  margin-top: 0;
  margin-bottom: 0;
}
