@import '../../styles/propertySets.css';

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 1000;

  /* Dimensions */
  width: 100vw;
  padding: 12px 0;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--matterColor);
  color: var(--matterColorNegative);
}

.message {
  @apply --marketplaceH5FontStyles;
  margin-left: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.cookieButtons {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin: 0 24px;

  @media (--viewportMedium) {
    grid-template-columns: 1fr 1fr;
    margin: 0 0 0 24px;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorDark);

  /* Dimensions */
  padding: 8px 12px 8px 12px;
  margin: 0;

  /* background-color: transparent; */
  background-color: var(--matterColorNegative);
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    /* background-color: var(--matterColorNegative); */
    background-color: transparent;
    /* color: var(--matterColorDark); */
    color: var(--matterColorLight);
    transition: var(--transitionStyleButton);
  }
}

.rejectBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  /* color: var(--matterColorDark); */
  color: var(--matterColorLight);

  /* Dimensions */
  padding: 8px 12px 8px 12px;
  margin: 0;

  /* background-color: var(--matterColorNegative); */
  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    /* background-color: transparent; */
    background-color: var(--matterColorNegative);
    /* color: var(--matterColorLight); */
    color: var(--matterColorDark);
    transition: var(--transitionStyleButton);
  }
}
