@import '../../styles/propertySets.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--matterColorNegative);

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.reviewContent {
  margin: 0;
  white-space: pre-line;

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }
}

.reviewInfo {
  @apply --marketplaceH5FontStyles;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
