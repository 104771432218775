@import '../../styles/propertySets.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.avatar {
  flex-shrink: 0;
  margin-right: 24px;

  /* Height & width */
  height: 72px;
  width: 72px;
}

.info {
  flex: 1;
}

.heading {
  @apply --marketplaceH3FontStyles;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  margin: 0px;
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  @apply --marketplaceH4FontStyles;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 5px;
}

.links {
  @apply --marketplaceH4FontStyles;
  margin: 8px 0 0;
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.reviewsInfo {
  /* Font */
  @apply --marketplaceSmallFontStyles;
  font-weight: var(--fontWeightRegular);
  line-height: 1;
  margin: 8px 0 0;
  display: flex;
  align-items: center;
}

.reviewsStar {
  height: 16px;
  width: 16px;
  margin: 0 5px;
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  @apply --marketplaceH4FontStyles;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.stravaProfileContainer {
  display: flex;
  align-items: center;
  margin: 2px 0;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin: -1px 0;
  }

  &:hover {
    color: var(--marketplaceColor);
  }
}

.stravaIcon {
  margin-right: 6px;
  border-radius: 4px;
}

.stravaUserName {
  text-transform: capitalize;
}
