.root {
  display: flex;
  align-items: center;
}

.tooltip {
  max-width: 260px;
  text-transform: none;
  font-weight: var(--fontWeightRegular);
}
